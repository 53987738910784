
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$languagenavigation-toggle-font-weight: $font-weight-normal !default;

$languagenavigation-menu-padding-y: $spacer * 0.5 !default;
$languagenavigation-menu-padding-x: $grid-gutter-width * 0.5 !default;

// desktop
$languagenavigation-breakpoint: $desktop-breakpoint;

$languagenavigation-desktop-toggle-font-weight: $languagenavigation-toggle-font-weight !default;

.languagenavigation {
  @include media-breakpoint-down(get-breakpoint-key($languagenavigation-breakpoint, -1)) {
    width: 100%;

    .dropdown-toggle {
      font-weight: $languagenavigation-toggle-font-weight;
    }

    &:not(.is-static) {
      .dropdown-toggle {
        text-align: inherit;
        border-radius: 0;
        box-shadow: none;
      }

      .dropdown-menu {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        padding: $languagenavigation-menu-padding-y $languagenavigation-menu-padding-x;
        border: 0 none;
        border-radius: 0;

        .dropdown-header {
          padding-left: 0;
          padding-right: 0;
        }

        .dropdown-item {
          display: flex;
          justify-content: space-between;
          padding-left: 0;
          padding-right: 0;
          background-color: $dropdown-bg;
          color: $dropdown-link-color;
          border-radius: 0;
        }
      }
    }

    &.is-static {
      .dropdown-toggle:not(:hover):not(:active) {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  @include media-breakpoint-up($languagenavigation-breakpoint) {
    .dropdown-toggle {
      font-weight: $languagenavigation-desktop-toggle-font-weight;

      &:not(:hover):not(:active) {
        background-color: transparent;
        border-color: transparent;
      }
    }

    .dropdown-menu {
      .dropdown-item {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
