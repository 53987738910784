
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$headeraccount-accountmenu-box-shadow: map-get($shadows, 'header') !default;

// desktop
$headeraccount-desktop: $desktop-breakpoint !default;

$headeraccount-desktop-btn-max-width: 250px !default;
$headeraccount-desktop-btn-icon-gap: $spacer * 0.25 !default;

$headeraccount-desktop-dropdown-menu-box-shadow: map-get($shadows, 'dropdown') !default;

.header-account {
  .dropdown {
    .dropdown-menu {
      display: none !important;
    }
  }

  @include media-breakpoint-down(get-breakpoint-key($headeraccount-desktop, -1)) {
    .btn {
      &:not(:hover):not(:active) {
        background-color: transparent;
        border-color: transparent;
      }

      > span {
        @include sr-only();
      }
    }

    .dropdown {
      .dropdown-toggle {
        position: relative;

        .toggle-handler {
          position: absolute;
          inset: 0;
        }
      }

      .account-menu {
        position: fixed;
        inset: 0;
        overflow: hidden;
        overflow-y: auto;

        > div {
          box-shadow: $headeraccount-accountmenu-box-shadow, inset $headeraccount-accountmenu-box-shadow;
        }
      }
    }
  }

  @include media-breakpoint-up($headeraccount-desktop) {
    position: relative;

    .btn {
      display: flex;
      align-items: center;
      max-width: $headeraccount-desktop-btn-max-width;

      > span {
        display: block;
        overflow: hidden;
        margin-left: $headeraccount-desktop-btn-icon-gap;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .dropdown {
      .account-menu {
        position: absolute;
        top: 100%;
        right: 0;
        float: left;
        margin-top: $dropdown-spacer;
        width: auto;
        min-width: $dropdown-min-width * 1.5;
        border: $dropdown-border-width solid $dropdown-border-color;
        border-radius: $dropdown-border-radius;
        box-shadow: $headeraccount-desktop-dropdown-menu-box-shadow;
      }
    }
  }
}
