
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$headersearch-backdrop-zindex: $zindex-sticky + 10 !default;

$headersearch-form-gap: $spacer * 0.5 !default;
$headersearch-form-height: $spacer * 2 !default;
$headersearch-form-border: map-get($borders, 'bold') !default;
$headersearch-form-zindex: $headersearch-backdrop-zindex + 2 !default;
$headersearch-form-focus-border: set-nth($headersearch-form-border, 3, $primary) !default;

$headersearch-expanded-form-offset-x: 2em !default;

$headersearch-form-submit-padding-y: 0 !default;
$headersearch-form-submit-padding-x: $headersearch-form-height * 0.33 !default;
$headersearch-form-focus-submit-color: $primary !default;

$headersearch-form-input-padding-y: $headersearch-form-submit-padding-y !default;
$headersearch-form-input-padding-x: $headersearch-form-submit-padding-x !default;
$headersearch-form-input-bg: $input-bg !default;
$headersearch-form-input-placeholder-color: $body-color !default;

$headersearch-results-bg: $white !default;
$headersearch-results-zindex: $headersearch-backdrop-zindex + 1 !default;

$headersearch-results-header-close-color: inherit !default;
$headersearch-results-header-close-hover-color: $link-hover-color !default;

$headersearch-results-body-resulttype-padding-y: $spacer !default;
$headersearch-results-body-resulttype-padding-x: 0 !default;
$headersearch-results-body-resulttype-border: map-get($borders, 'base') !default;

$headersearch-results-body-resulttype-title-gap: $spacer * 0.3 !default;
$headersearch-results-body-resulttype-title-font-size: $font-size-sm !default;
$headersearch-results-body-resulttype-title-color: $dark !default;

$headersearch-results-body-resulttype-noresult-font-weight: $font-weight-bold !default;

$headersearch-results-body-resulttype-itemlist-item-gutter: 10px !default;
$headersearch-results-body-resulttype-itemlist-item-padding-y: $spacer * 0.4 !default;
$headersearch-results-body-resulttype-itemlist-item-padding-x: $headersearch-results-body-resulttype-itemlist-item-padding-y !default;
$headersearch-results-body-resulttype-itemlist-item-border-radius: $border-radius !default;
$headersearch-results-body-resulttype-itemlist-item-img-max-width: 75px !default;
$headersearch-results-body-resulttype-itemlist-item-img-fallback-size: 100% !default;
$headersearch-results-body-resulttype-itemlist-item-img-fallback-color: $imgfallback !default;
$headersearch-results-body-resulttype-itemlist-item-hover-bg: $gray-300 !default;
$headersearch-results-body-resulttype-itemlist-item-hover-img-fallback-color: darken($imgfallback, 10%) !default;

// desktop
$headersearch-breakpoint: $desktop-breakpoint !default;

$headersearch-desktop-backdrop-bg: rgba($black, 0.4) !default;

$headersearch-desktop-form-gap: $spacer * 0.5 !default;
$headersearch-desktop-form-width: 300px !default;

$headersearch-desktop-expanded-form-offset-x: 0 !default;

$headersearch-desktop-results-y: $spacer * -0.75 !default;
$headersearch-desktop-results-width: 60vw !default;
$headersearch-desktop-results-max-width: 800px !default;
$headersearch-desktop-results-bg: $headersearch-results-bg !default;
$headersearch-desktop-results-border-radius: $border-radius !default;
$headersearch-desktop-results-box-shadow: map-get($shadows, 'header') !default;

$headersearch-desktop-results-header-padding-y: 0 !default;
$headersearch-desktop-results-header-padding-x: $grid-gutter-width * 0.5 !default;
$headersearch-desktop-results-header-height: calc($headersearch-form-height + ($headersearch-desktop-results-y * -2)) !default;
$headersearch-desktop-results-header-border: map-get($borders, 'base') !default;

$headersearch-desktop-results-body-max-height: 70vh !default;

.header-search {
  position: relative;

  .search-backdrop {
    display: none;
    position: relative;
    z-index: $headersearch-backdrop-zindex;
  }

  .search-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: $headersearch-form-gap 0;
    height: $headersearch-form-height;
    background-color: $headersearch-form-input-bg;
    border: $headersearch-form-border;
    border-radius: $headersearch-form-height;
    z-index: $headersearch-form-zindex;

    .search-input {
      flex: 1 1 100%;
      margin: 0;
      padding: $headersearch-form-input-padding-y $headersearch-form-input-padding-x;
      height: 100%;
      background: none;
      border: 0 none;
      outline: 0 none;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      font-style: inherit;
      box-shadow: none;

      &::placeholder {
        color: $headersearch-form-input-placeholder-color;
      }

      &:last-child {
        padding-left: 0;
      }
    }

    .search-submit {
      flex: 0 0 auto;
      padding: $headersearch-form-submit-padding-y $headersearch-form-submit-padding-x;
      background: none;
      border: 0 none;

      .clear {
        cursor: pointer;
      }

      &:last-child {
        padding-left: 0;
      }
    }
  }

  .search-results {
    display: none;
    z-index: $headersearch-results-zindex;

    .results-header {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;

      .close {
        color: $headersearch-results-header-close-color;

        &:hover {
          color: $headersearch-results-header-close-hover-color;
        }
      }
    }

    .results-body {
      position: fixed;
      inset: 0;
      overflow-y: auto;
      background-color: $headersearch-results-bg;
      z-index: inherit;

      .resulttype {
        padding: $headersearch-results-body-resulttype-padding-y $headersearch-results-body-resulttype-padding-x;
        border-bottom: $headersearch-results-body-resulttype-border;

        .resulttype-title {
          margin-bottom: $headersearch-results-body-resulttype-title-gap;
          font-size: $headersearch-results-body-resulttype-title-font-size;
          color: $headersearch-results-body-resulttype-title-color;
        }

        .resulttype-noresults {
          font-weight: $headersearch-results-body-resulttype-noresult-font-weight;
        }

        .resulttype-itemlist {
          @include list-unstyled();

          .resulttype-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0 ($headersearch-results-body-resulttype-itemlist-item-padding-x * -1);
            padding: $headersearch-results-body-resulttype-itemlist-item-padding-y $headersearch-results-body-resulttype-itemlist-item-padding-x;
            border-radius: $headersearch-results-body-resulttype-itemlist-item-border-radius;
            color: inherit;
            text-decoration: none;

            > * {
              display: block;
              margin: 0;
              padding-left: $headersearch-results-body-resulttype-itemlist-item-gutter * 0.5;
              padding-right: $headersearch-results-body-resulttype-itemlist-item-gutter * 0.5;

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                padding-right: 0;
              }
            }

            > img,
            > .is-fallback-image {
              max-width: $headersearch-results-body-resulttype-itemlist-item-img-max-width;

              .bi {
                width: $headersearch-results-body-resulttype-itemlist-item-img-fallback-size;
                height: $headersearch-results-body-resulttype-itemlist-item-img-fallback-size;
                color: $headersearch-results-body-resulttype-itemlist-item-img-fallback-color;
              }
            }

            &:hover {
              background-color: $headersearch-results-body-resulttype-itemlist-item-hover-bg;

              > .is-fallback-image {
                .bi {
                  color: $headersearch-results-body-resulttype-itemlist-item-hover-img-fallback-color;
                }
              }
            }
          }
        }
      }
    }
  }

  &.focus {
    .search-form {
      border: $headersearch-form-focus-border;

      .search-submit {
        color: $headersearch-form-focus-submit-color;
      }
    }
  }

  &.expanded {
    .search-form {
      margin-right: $headersearch-expanded-form-offset-x;
    }

    .search-results {
      display: block;
    }
  }

  @include media-breakpoint-up($headersearch-breakpoint) {
    .search-backdrop {
      position: fixed;
      inset: 0;
      background-color: $headersearch-desktop-backdrop-bg;
    }

    .search-form {
      margin: $headersearch-desktop-form-gap 0;
      width: $headersearch-desktop-form-width;
    }

    .search-results {
      display: none;
      position: absolute;
      top: $headersearch-desktop-results-y;
      left: 50%;
      width: $headersearch-desktop-results-width;
      max-width: $headersearch-desktop-results-max-width;
      background-color: $headersearch-desktop-results-bg;
      border-radius: $headersearch-desktop-results-border-radius;
      box-shadow: $headersearch-desktop-results-box-shadow;
      transform: translate3d(-50%, 0, 0);

      .results-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: static;
        padding: $headersearch-desktop-results-header-padding-y $headersearch-desktop-results-header-padding-x;
        height: calc($headersearch-form-height + ($headersearch-desktop-results-y * -2));
        border-bottom: $headersearch-desktop-results-header-border;
      }

      .results-body {
        position: static;
        max-height: $headersearch-desktop-results-body-max-height;
        background: none;

        .resulttype {
          &:last-child {
            border-bottom: 0 none;
          }
        }
      }
    }

    &.expanded {
      .search-backdrop {
        display: block;
      }

      .search-form {
        margin-right: $headersearch-desktop-expanded-form-offset-x;
      }

      .search-results {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
