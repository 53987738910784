
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// Logo Format is 90x100 (WxH) pixel

// mobile
$headerlogo-gap: $spacer * 0.5 !default;
$headerlogo-height: 46px !default;

// desktop
$headerlogo-breakpoint: $desktop-breakpoint !default;

$headerlogo-desktop-gap: 0 !default;
$headerlogo-desktop-height: 96px !default;
$headerlogo-desktop-transition-in: all $header-collapse-transition-ease-in $header-collapse-transition-speed-in !default;
$headerlogo-desktop-transition-out: all $header-collapse-transition-ease-out $header-collapse-transition-speed-out !default;
$headerlogo-desktop-scrollingdown-height: 65px !default;

.header-logo {
  margin-top: $headerlogo-gap;
  width: $headerlogo-height * 0.9;

  a {
    display: block;

    img {
      display: block;
      margin: 0;
    }
  }

  @include media-breakpoint-up($headerlogo-breakpoint) {
    margin-top: $headerlogo-desktop-gap;
    width: $headerlogo-desktop-height * 0.9;
    transition: $headerlogo-desktop-transition-out;

    .hide-header-nav & {
      &:not(.is-static) {
        margin-right: ($headerlogo-desktop-height * 0.9) - ($headerlogo-desktop-scrollingdown-height * 0.9);
        width: $headerlogo-desktop-scrollingdown-height * 0.9;
        transition: $headerlogo-desktop-transition-in;
      }
    }
  }
}
