
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$counter-padding: $spacer * 0.25 !default;
$counter-font-size: 12px !default;
$counter-font-weight: $font-weight-bold !default;

$counter-offset-y: $counter-font-size * -0.5 !default;
$counter-offset-x: $counter-font-size * -0.5 !default;
$counter-zindex: $zindex-dropdown - 10 !default;

.counter-wrapper {
  position: relative;

  .counter {
    position: absolute;
    top: $counter-offset-y;
    right: $counter-offset-x;
    z-index: $counter-zindex;
  }
}

.counter {
  padding: $counter-padding;
  min-width: calc(1em + #{$counter-padding * 2});
  min-height: calc(1em + #{$counter-padding * 2});
  border-radius: 2em;
  font-size: $counter-font-size;
  font-weight: $counter-font-weight;
  white-space: nowrap;
}
