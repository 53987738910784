
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$headerwishlist-popover-min-width: 250px !default;

.header-wishlist {
  #HeaderWishlistButton {
    &:not(:hover):not(:active) {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .popover {
    min-width: $headerwishlist-popover-min-width;

    .arrow {
      // icon font-size = 125%, defined by bootstrap-vue
      // 1.25em / 2 = 0.625em
      margin-left: calc($btn-border-width + $btn-padding-x-xs + 0.625em - ($popover-arrow-width * 0.5));
      margin-right: calc($btn-border-width + $btn-padding-x-xs + 0.625em - ($popover-arrow-width * 0.5));
    }
  }
}
