
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$headerusermenu-gap: $spacer * 0.5 !default;
$headerusermenu-item-gutter: $spacer * 0.6 !default;

.header-user-menu {
  display: flex;
  margin: $headerusermenu-gap ($headerusermenu-item-gutter * -0.5);

  > * {
    margin-left: $headerusermenu-item-gutter * 0.5;
    margin-right: $headerusermenu-item-gutter * 0.5;
  }
}
